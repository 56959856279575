import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title={"Contact us"} />
      <h1 className={"title"}>Contact us</h1>
    </Layout>
  )
}

export default ContactPage
